<template>
  <div>
    <b-card no-body v-if="errorItems.length === 0 && items.length === 0">
      <b-card-header class="pb-50">
        <h5>Please select a file to be imported.</h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-form-file id="extension" v-model="importFile" @change="initiateImportJob" accept=".xlsx, .csv, .tsv" :disabled="processing" />
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body v-if="errorItems.length > 0">
      <b-card-header class="pb-50">
        <h5>Invalid / Corrupted Rows</h5>

        <b-button class="pull-right" variant="primary" :disabled="true"> Download Corrupt XLSX </b-button>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-table responsive="sm" :items="errorItems" class="w-100">
            <!-- Column: programStart -->
            <template #cell(row)="data">
              <span class="text-nowrap">
                <span v-if="data.item.stage === 5"> (near) </span>
                {{ data.value }}
              </span>
            </template>
          </b-table>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body v-if="items.length > 0">
      <b-card-header class="pb-50">
        <h5>Successfully Imported Reports</h5>

        <b-button class="pull-right" variant="primary" @click="doSelectedPdfExport" :disabled="processing"> Download Reports </b-button>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-table :fields="fields" :items="items" responsive="sm" class="w-100">
            <!-- A virtual composite column -->
            <template #cell(id)="data">
              <router-link target="_blank" :to="{ name: 'apps-report-preview', params: { id: data.value } }">
                {{ data.value }}
              </router-link>
            </template>

            <!-- A custom formatted column -->
            <template #cell(Popularity)="data">
              <b-progress :value="data.value.value" max="100" :variant="data.value.variant" />
            </template>

            <template #cell(order_status)="data">
              <b-badge :variant="data.value.variant">
                {{ data.value.status }}
              </b-badge>
            </template>

            <!-- Column: programStart -->
            <template #cell(programStart)="data">
              <span class="text-nowrap">
                {{ moment(data.value).format('L') }}
              </span>
            </template>

            <!-- Column: programEnd -->
            <template #cell(programEnd)="data">
              <span class="text-nowrap">
                {{ moment(data.value).format('L') }}
              </span>
            </template>

            <!-- A virtual composite column -->
            <template #cell(subTotal)="data">
              {{ '$' + data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCard,
  BTable,
  BFormFile,
  BProgress,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BCardHeader,
  BCardBody,
  BButton,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import store from '@/store/index';
import vSelect from 'vue-select';
import moment from 'moment';

export default {
  components: {
    VueGoodTable,
    BFormFile,
    BAvatar,
    BBadge,
    BCard,
    BProgress,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: 'id', label: 'Report Id' },
        { key: 'studentId', label: 'Student Id' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'programName', label: 'Program' },
        { key: 'programStart', label: 'Start' },
        { key: 'programEnd', label: 'End' },

        { key: 'status', label: 'Status' },
      ],
      items: [],

      errorItems: [],

      importFile: undefined,

      showSuccessfulTable: false,
      showErrorTable: false,

      processing: false,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-info',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    doSelectedPdfExport() {
      this.processing = true;
      let ids = this.items.map((item) => item.id);

      this.$http
        .post(`v1/jobs/export`, { entity: 'report', metadata: { ids } })
        .then((response) => {
          console.log(response);

          this.$router.push({ name: 'apps-job-list-pending' });
        })
        .catch((e) => {
          this.processing = false;
          console.error(e);
        });
    },

    initiateImportJob(event) {
      this.processing = true;

      const fd = new FormData();
      fd.append('xlsx', event.target.files[0]);

      if (fd.get('xlsx') === undefined) {
        this.processing = false;
        throw new Error('INVLID FILE BRO');
      }

      this.$http
        .post(`v1/reports/import`, fd, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.processing = false;

          if (response.status === 200) {
            const { successful, failed } = response.data;

            this.errorItems = failed.map((row) => {
              console.log(row);

              let adt = `- `;

              if (row.row) {
                let metadata = row.row;

                if (metadata['Email Address']) {
                  adt += `- Email: ${metadata['Email Address']}`;
                }

                if (metadata['First Name'] && metadata['Last Name']) {
                  adt += `- Name: ${metadata['First Name']} ${metadata['Last Name']}`;
                }

                if (metadata['Program Name']) {
                  adt += `- Program: ${metadata['Program Name']}`;
                }
              }

              return {
                stage: row.stage,
                row: row.index,
                message: row.message,

                additional: adt.substr(2),
              };
            });

            this.items = successful.map((row) => row);
          }
        })
        .catch((e) => {
          this.processing = false;
          console.error(e);
        });
    },
  },
  created() {
    this.$http.get('/good-table/basic').then((res) => {
      this.rows = res.data;
    });
  },
  setup() {
    return { moment };
  },
};
</script>
